import * as actionTypes from './constant';

export const initialState = {
    availableBalance: '0.00',
    automatedBank: null,
    manualBank: null
};

// ==============================|| AUTHENTICATION REDUCER ||============================== //

const walletReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_USER_WALLET:
            return {
                ...state,
                ...action.payload
            };
        case actionTypes.GET_MANUAL_BANK_ACCOUNT:
            return {
                ...state,
                manualBank: action.payload
            };
        case actionTypes.GET_USER_BANK_ACCOUNT:
            return {
                ...state,
                automatedBank: action.payload
            };

        default:
            return state;
    }
};

export default walletReducer;
