import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import reducer from './reducer';

const initialState = {};
const middleWare = [thunk];

// ==============================|| REDUX - MAIN STORE ||============================== //

const store = createStore(reducer, initialState, applyMiddleware(...middleWare));
const persister = 'Free';

export { store, persister };
