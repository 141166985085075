// theme constant
export const gridSpacing = 3;
export const drawerWidth = 260;
export const appDrawerWidth = 320;

// auth const
export const USER_LOADING = 'AUTH@USER_LOADING';
export const USER_LOADED = 'AUTH@USER_LOADED';
//
export const LOGIN_SUCCESS = 'AUTH@LOGIN_SUCCESS';
export const LOGIN_FAILED = 'AUTH@LOGIN_FAILED';
//
export const REGISTER_SUCCESS = 'AUTH@REGISTER_SUCCESS';
export const REGISTER_FAILED = 'AUTH@REGISTER_FAILED';
export const RESET_PASSWORD = 'AUTH@RESET_PASSWORD';
//
export const AUTH_ERROR = 'AUTH@AUTH_ERROR';
export const USER_LOGOUT = 'AUTH@USER_LOGOUT';

export const SET_CONTACT_INFORMATION = 'AUTH@SET_CONTACT_INFORMATION';

// wallet const
export const GET_USER_WALLET = 'WALLET@GET_USER_WALLET';
export const GET_USER_BANK_ACCOUNT = 'WALLET@GET_USER_BANK_ACCOUNT';
export const GET_MANUAL_BANK_ACCOUNT = 'WALLET@GET_MANUAL_BANK_ACCOUNT';

//services
export const GET_SERVICES = 'GET_SERVICES';
export const SET_API_SERVICES = 'SET_API_SERVICES';
export const SET_API_KEYS = 'SET_API_KEYS';
