import { create } from 'apisauce';
import { store } from '../store';
import { logOutUser } from 'store/authAction';

export const DEVICE_CHANGE = 'Your session has expire or you have log in on another device please logout and login again';

const monitorBadToken = (response) => {
    if (response.status === 401) {
        store.dispatch(logOutUser());
        toast.info('Your session has expired please login again');
    }

    if (response.status === 409 && response?.data?.message === DEVICE_CHANGE) {
        store.dispatch(logOutUser());
        toast.info('Your session has expired please login again');
    }
};

export const api = create({
    baseURL: 'https://app.jaybeesdataplug.com'
});

// export const api = create({
//     baseURL: 'http://127.0.0.1:8080'
// });

api.addMonitor(monitorBadToken);
