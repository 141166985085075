/* eslint-disable react/prop-types */
import React from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';
import { css } from 'styled-components/macro'; //eslint-disable-line
import HeaderBase, { NavLinks, NavLink, PrimaryLink } from '../headers/light.js';
import { SectionHeading } from '../misc/Headings.js';
import { SectionDescription } from '../misc/Typography.js';
import { PrimaryButton as PrimaryButtonBase } from '../misc/Buttons.js';
import { Container, ContentWithVerticalPadding } from '../misc/Layouts.js';
import { ReactComponent as CheckboxIcon } from 'feather-icons/dist/icons/check-circle.svg';
import { ReactComponent as QuotesLeftIconBase } from '../images/quotes-l.svg';
import { ReactComponent as SvgDecoratorBlob1 } from '../images/dot-pattern.svg';
import imageSource from '../images/jaybes.png';

import appleIconImageSrc from '../images/apple-icon.png';
import googlePlayIconImageSrc from '../images/google-play-icon.png';
import { useSelector } from 'react-redux';
import { Button, Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router';

const Header = tw(HeaderBase)`max-w-none`;
const Row = tw.div`flex flex-col lg:flex-row justify-center items-center lg:pt-2 max-w-screen-2xl mx-auto sm:px-8`;
const Column = tw.div``;
const TextColumn = tw(Column)`mr-auto lg:mr-0 max-w-lg lg:max-w-xl xl:max-w-2xl`;
const Heading = tw(SectionHeading)`text-left text-primary-600 leading-snug xl:text-5xl`;
const Description = tw(SectionDescription)`mt-4 lg:text-base text-gray-700 max-w-lg`;
const PrimaryButton = tw(PrimaryButtonBase)`mt-8 inline-block w-56 tracking-wide text-center py-5 no-underline`;
const FeatureList = tw.ul`mt-12 leading-loose`;
const Feature = tw.li`flex items-center`;
// const FeatureIcon = tw(CheckboxIcon)`w-5 h-5 text-primary-500`;
const FeatureText = tw.p`ml-2 font-medium text-gray-700`;
const ImageColumn = tw(Column)`ml-auto lg:mr-0 relative mt-16 lg:mt-0 lg:ml-32`;
const ImageContainer = tw.div`relative z-40 transform xl:-translate-x-24 xl:-translate-y-16`;
const Image = tw.img`max-w-full w-96 rounded-t sm:rounded relative z-20`;
const Offsetbackground = tw.div`absolute inset-0 bg-gray-300 rounded xl:-mb-8`;
const ImageDecoratorBlob = styled(SvgDecoratorBlob1)`
    ${tw`pointer-events-none z-10 absolute right-0 bottom-0 transform translate-x-10 translate-y-10 h-32 w-32 opacity-25 text-gray-900 fill-current`}
`;
const Testimonial = tw.div`max-w-sm rounded-b md:rounded-none relative sm:absolute bottom-0 inset-x-0 z-20 px-8 py-6 sm:px-10 sm:py-8 bg-primary-900 text-gray-400 font-medium transform md:-translate-x-32 text-sm leading-relaxed md:-mr-16 xl:mr-0`;
const QuotesLeftIcon = tw(
    QuotesLeftIconBase
)`w-16 h-16 md:w-12 md:h-12 absolute top-0 left-0 text-gray-100 md:text-red-500 transform translate-x-1 md:-translate-x-1/2 md:-translate-y-5 opacity-10 md:opacity-100`;
const Quote = tw.blockquote``;
const CustomerName = tw.p`mt-4 font-bold`;
const CustomerCompany = tw.p`mt-1 text-sm text-gray-500`;

const LinksContainer = tw.div`mt-8 lg:mt-16 flex flex-col items-center sm:block`;
const Link = styled.a`
    ${tw`w-56 p-3 sm:p-4 text-sm sm:text-base font-bold uppercase tracking-wider rounded-full no-underline inline-flex justify-center items-center mt-6 first:mt-0 sm:mt-0 sm:ml-8 first:ml-0 bg-gray-100 hocus:bg-gray-300 text-gray-900 hocus:text-gray-900 shadow hover:shadow-lg focus:shadow-outline focus:outline-none transition duration-300`}
    img {
        ${tw`inline-block h-8 mr-3`}
    }
    span {
        ${tw`leading-none inline-block`}
    }
`;

export default ({
    heading = `Jaybeesdataplug\nYour best data plug`,
    description = 'Buy cheap and affordable Data Plans, Airtime,electricity bills and cable subscription, REGISTER NOW !!!',
    imageSrc = imageSource,
    imageDecoratorBlob = true,
    buttonRounded = true
}) => {
    const buttonRoundedCss = buttonRounded && tw`rounded-full`;
    const { isAuthenticated } = useSelector((state) => state.auth);
    const navigate = useNavigate();

    const navLinks = [
        <NavLinks key={1}>
            <NavLink href="/#">About</NavLink>
            <NavLink href="/#">Blog</NavLink>
            <NavLink href="/#">Pricing</NavLink>
            <NavLink href="/#">Contact Us</NavLink>
        </NavLinks>,
        <NavLinks key={2}>
            {isAuthenticated ? (
                <PrimaryLink style={{ background: 'teal' }} css={buttonRoundedCss} href="/dashboard">
                    Dashboard
                </PrimaryLink>
            ) : (
                <>
                    <NavLink href="/login" tw="lg:ml-12!">
                        Login
                    </NavLink>
                    <PrimaryLink style={{ background: 'teal' }} css={buttonRoundedCss} href="/register">
                        Sign Up
                    </PrimaryLink>
                </>
            )}
        </NavLinks>
    ];
    return (
        <>
            <Header links={navLinks} />
            <Container>
                <ContentWithVerticalPadding>
                    <Row>
                        <TextColumn>
                            <Typography variant="h3">Welcome to Jaybeesdataplug</Typography>
                            <Typography mt={'10px'} fontFamily={'Roboto'} variant={'h1'} color={'teal'}>
                                {heading}
                            </Typography>
                            <Typography my={'20px'} variant="body2" fontSize={'16px'}>
                                {description}
                            </Typography>
                            <Stack direction={'row'} spacing={2}>
                                {isAuthenticated ? (
                                    <Button
                                        onClick={() => navigate('/dashboard')}
                                        sx={{ borderRadius: '40px', bgcolor: 'teal', px: '50px' }}
                                        size={'large'}
                                        variant={'contained'}
                                    >
                                        Go to Dashboard
                                    </Button>
                                ) : (
                                    <>
                                        <Button
                                            onClick={() => navigate('/register')}
                                            sx={{ borderRadius: '40px', bgcolor: 'teal', px: '50px' }}
                                            size={'large'}
                                            variant={'contained'}
                                        >
                                            Register
                                        </Button>
                                        <Button
                                            onClick={() => navigate('/login')}
                                            size={'large'}
                                            variant={'text'}
                                            sx={{ color: 'teal', borderRadius: '40px', px: '50px' }}
                                        >
                                            Login
                                        </Button>
                                    </>
                                )}
                            </Stack>

                            <Stack mt={'30px'} width={'100%'} direction={{ xs: 'column', sm: 'row' }}>
                                <Link
                                    href={'https://play.google.com/store/apps/details?id=com.jaybees.datahub&pcampaignid=web_share'}
                                    style={{ display: 'flex', flex: 1, width: '100%' }}
                                >
                                    <img src={googlePlayIconImageSrc} alt="" />
                                    <span>{'Get it on Google play'}</span>
                                </Link>
                                <Link href={'http://apple.com'} style={{ display: 'flex', flex: 1, width: '100%' }}>
                                    <img src={appleIconImageSrc} alt="" />
                                    <span>{'App store'}</span>
                                </Link>
                            </Stack>
                        </TextColumn>
                        <ImageColumn>
                            <ImageContainer>
                                <Image src={imageSrc} />
                                {imageDecoratorBlob && <ImageDecoratorBlob />}
                            </ImageContainer>
                            {/* <Offsetbackground /> */}
                        </ImageColumn>
                    </Row>
                </ContentWithVerticalPadding>
            </Container>
        </>
    );
};
