import { Button } from '@mui/material';
import React from 'react';
import { useNavigate, useParams } from 'react-router';
import ContainerBody from './component/helpers/ContainerBody.js';
import Hero from './component/hero/TwoColumnWithFeaturesAndTestimonial.js';
import AnimationRevealPage from './component/helpers/AnimationRevealPage';
import Footer from './component/footers/MiniCenteredFooter.js';
import FAQ from './component/faqs/SingleCol';
import tw from 'twin.macro';
import Features from './component/features/DashedBorderSixFeatures.js';
import DownloadApp from './component/cta/DownloadApp.js';
import FeatureWithSteps from './component/features/TwoColWithSteps.js';
import macHeroScreenshotImageSrc from './component/images/jaybees_screenshot.png';

export default function LandingPage() {
    const faqRef = React.useRef(null);
    const path = window.location.pathname;

    const Subheading = tw.span`uppercase tracking-widest font-bold text-primary-500`;
    const HighlightedText = tw.span`text-primary-500`;
    const HighlightedTextInverse = tw.span`bg-gray-100 text-primary-500 px-4 transform -skew-x-12 inline-block mt-3`;
    const navigate = useNavigate();

    const SSubheading = tw.span`uppercase tracking-widest font-bold text-primary-500`;
    const HHighlightedText = tw.span`text-primary-500`;

    const handleNavigate = () => {
        navigate('/dashboard');
    };

    return (
        <AnimationRevealPage>
            <Hero />
            <FeatureWithSteps
                subheading={<SSubheading>STEPS</SSubheading>}
                heading={
                    <>
                        Easy to <HHighlightedText>Get Started.</HHighlightedText>
                    </>
                }
                textOnLeft={false}
                imageSrc={macHeroScreenshotImageSrc}
                imageDecoratorBlob={true}
                decoratorBlobCss={tw`xl:w-40 xl:h-40 opacity-15 -translate-x-1/2 left-1/2`}
            />
            <Features />
            <DownloadApp
                text={
                    <>
                        People around you are enjoying great serving using the{' '}
                        <HighlightedTextInverse>Jaybeesdataplug App.</HighlightedTextInverse>
                    </>
                }
            />

            <Footer />
        </AnimationRevealPage>
    );
}
