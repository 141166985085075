import React from 'react';
import { Box, Typography } from '@mui/material';
import logo from '../assets/images/animated_logo.gif';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useNavigate } from 'react-router';
import { getAllServices } from 'store/ServiceAction';
import whatsapp_logo from '../assets/images/whatsapp.jpg';
import { IconBrandWhatsapp } from '@tabler/icons';

export default function ProtectedRoute({ component }) {
    const dispatch = useDispatch();
    // check for authentication
    const { isAuthenticated, isLoading } = useSelector((state) => state.auth);

    React.useEffect(() => {
        // get services if is authenticated;
        if (isAuthenticated) {
            dispatch(getAllServices());
        }
    }, []);

    if (isLoading) {
        return (
            <Box width="100%" height={'90vh'} display="flex" flexDirection="column" justifyContent="center" alignItems={'center'}>
                <img src={logo} alt="loading..." height={60} width={60} />
            </Box>
        );
    }

    if (!isAuthenticated) {
        return <Navigate to={'/login'} />;
    }

    return <>{component}</>;
}
