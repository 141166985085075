import { combineReducers } from 'redux';

// reducer import
import customizationReducer from './customizationReducer';
import authReducer from './authReducer';
import walletReducer from './walletReducer';
import serviceReducer from './ServiceReducer';

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
    customization: customizationReducer,
    auth: authReducer,
    wallet: walletReducer,
    service: serviceReducer
});

export default reducer;
