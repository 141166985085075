import { api } from './api.config';
import { store } from '../store';

export const SignInUser = async (data) => api.post('/api/v1/agent/auth/login', data);
export const SignUpUser = async (data) => api.post('/api/v1/agent/auth/register', data);

export const getUser = async () => {
    const token = store.getState().auth.token;

    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    };

    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }

    return api.get('/api/v1/agent/', {}, config);
};

export const resetUserPassword = async (data) => {
    return api.post('/api/v1/agent/auth/reset-password', data);
};

export const resetUserPasswordOtp = async (data) => {
    return api.post('/api/v1/agent/auth/otp-reset-password', data);
};

export const resetUserPasswordConfirm = async (data) => {
    return api.post('/api/v1/agent/auth/confirm-reset-password', data);
};

export const changeUserPassword = async (data) => {
    const token = store.getState().auth.token;

    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    };

    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }

    return api.post('/api/v1/agent/auth/change-password', data, config);
};

export const getAllNotifications = async () => {
    const token = store.getState().auth.token;

    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    };

    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }

    return api.get('/api/v1/agent/notify', {}, config);
};

export const getAllContacts = async () => {
    const token = store.getState().auth.token;

    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    };

    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }

    return api.get('/api/v1/agent/contact', {}, config);
};

export const getAllBanksAccount = async () => {
    const token = store.getState().auth.token;

    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    };

    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }

    return api.get('/api/v1/agent/banks', {}, config);
};

export const getAllManualBanksAccount = async () => {
    const token = store.getState().auth.token;

    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    };

    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }

    return api.get('/api/v1/agent/manual-funding', {}, config);
};

export const createVirtualAccount = async (payload) => {
    const token = store.getState().auth.token;

    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    };

    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }

    return api.post('/api/v1/agent/banks', payload, config);
};

export const getBankList = async () => {
    const token = store.getState().auth.token;

    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    };

    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }

    return api.get('/api/v1/agent/banks/list', {}, config);
};

export const getUserWallet = async (config) => api.get('/api/v1/agent/wallet/balance', {}, config);
