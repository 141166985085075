import * as actionTypes from './constant';

const user_session = localStorage.getItem('user_session') ? JSON.parse(localStorage.getItem('user_session')) : {};

// Function to check if the session is expired
const isSessionExpired = (expiryTime) => {
    return Date.now() > expiryTime;
};

export const initialState = {
    token: user_session.token || null,
    isLoading: false,
    isAuthenticated: user_session.token && !isSessionExpired(user_session.expiryTime) ? true : false,
    user: user_session.user || null,
    contacts: [],
    reset_password: null
};

// ==============================|| AUTHENTICATION REDUCER ||============================== //

const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.USER_LOADING:
            return {
                ...state,
                isLoading: true
            };
        case actionTypes.USER_LOADED:
            return {
                ...state,
                isLoading: false
            };
        case actionTypes.SET_CONTACT_INFORMATION:
            return {
                ...state,
                contacts: action.payload
            };
        case actionTypes.LOGIN_SUCCESS:
        case actionTypes.REGISTER_SUCCESS:
            const { token, user } = action.payload;
            const expiryTime = Date.now() + 3 * 60 * 60 * 1000; // 3 hours expiration
            localStorage.setItem('user_session', JSON.stringify({ token, user, expiryTime }));
            return {
                ...state,
                user,
                token,
                isAuthenticated: true,
                isLoading: false
            };
        case actionTypes.LOGIN_FAILED:
        case actionTypes.REGISTER_FAILED:
            return {
                ...state,
                isLoading: false
            };
        case actionTypes.RESET_PASSWORD:
            return {
                ...state,
                reset_password: { ...state.reset_password, ...action.payload }
            };
        case actionTypes.AUTH_ERROR:
        case actionTypes.USER_LOGOUT:
            localStorage.removeItem('user_session');
            return {
                user: null,
                isAuthenticated: false,
                isLoading: false,
                token: null
            };
        default:
            return state;
    }
};

export default authReducer;
