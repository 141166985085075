import * as serviceType from './constant';
import { getApiKeys, getApiServices, getServices } from '../api/api.services';

export const getAllServices = () => async (dispatch, getState) => {
    const response = await getServices();

    if (response.ok) {
        return dispatch({
            type: serviceType.GET_SERVICES,
            payload: response.data.data
        });
    }
};

export const getApiService = () => async (dispatch, getState) => {
    const response = await getApiServices();

    if (response.ok) {
        return dispatch({
            type: serviceType.SET_API_SERVICES,
            payload: response.data.data
        });
    }
};

export const getApiKey = () => async (dispatch, getState) => {
    const response = await getApiKeys();

    if (response.ok) {
        return dispatch({
            type: serviceType.SET_API_KEYS,
            payload: response.data.data
        });
    }
};
