import * as authApi from '../api/api.auth';
import * as authAction from './constant';
import { toast } from 'react-toastify';

export const loginUser = (data) => async (dispatch) => {
    dispatch({ type: authAction.USER_LOADING });

    const response = await authApi.SignInUser(data);

    if (response.ok) {
        toast.success(response.data.message);
        const { user, authorization } = response.data.data;
        dispatch({ type: authAction.LOGIN_SUCCESS, payload: { user, token: authorization.token } });
        return;
    }

    if (response.data) {
        toast.error(response.data.message);
        dispatch({ type: authAction.LOGIN_FAILED });
        return;
    }

    toast.error(response.problem);
    dispatch({ type: authAction.LOGIN_FAILED });
};

export const registerUser = (data) => async (dispatch) => {
    dispatch({ type: authAction.USER_LOADING });

    const response = await authApi.SignUpUser(data);

    if (response.ok) {
        toast.success(response.data.message);
        const { user, authorization } = response.data.data;
        dispatch({ type: authAction.REGISTER_SUCCESS, payload: { user, token: authorization.token } });
        return;
    }

    if (response.data) {
        toast.error(response.data.message);
        dispatch({ type: authAction.LOGIN_FAILED });
        return;
    }

    toast.error(response.problem);
    dispatch({ type: authAction.REGISTER_FAILED });
};

export const logOutUser = () => async (dispatch) => {
    dispatch({ type: authAction.USER_LOGOUT });
    toast.error('You have Logout');
};
