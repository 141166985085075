import { api } from './api.config';
import { store } from '../store';

export const getTransactionsHistory = async () => {
    const token = store.getState().auth.token;

    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    };

    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return api.get('/api/v1/agent/transactions', {}, config);
};

export const fundUserWallet = async (data) => {
    const token = store.getState().auth.token;

    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    };

    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return api.post('/api/v1/agent/wallet/fund', data, config);
};
