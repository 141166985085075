import React from 'react';
import {
    Grid,
    Link,
    Button,
    Input,
    Box,
    FormControlLabel,
    Checkbox,
    FormControl,
    InputLabel,
    OutlinedInput,
    InputAdornment,
    IconButton,
    Select,
    MenuItem,
    Stack,
    Typography,
    Divider,
    TextField,
    Avatar
} from '@mui/material';

// project imports
import SubCard from 'ui-component/cards/SubCard';
import MainCard from 'ui-component/cards/MainCard';
import SecondaryAction from 'ui-component/cards/CardSecondaryAction';
import { gridSpacing } from 'store/constant';
import { IconPhone, IconSearch, IconX } from '@tabler/icons';
import { useDispatch, useSelector } from 'react-redux';
import ModalC from 'views/dashboard/components/Modal';
import { logOutUser } from 'store/authAction';
import { LoadingButton } from '@mui/lab';
import { Formik } from 'formik';
import { changeUserPassword } from 'api/api.auth';
import { toast } from 'react-toastify';
import * as yup from 'yup';

const Support = () => {
    const dispatch = useDispatch();
    const [showModal, setShowModal] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const { user } = useSelector((state) => state.auth);
    const toggleModal = () => setShowModal(!showModal);

    return (
        <>
            <MainCard title="Support">
                <Box sx={{ px: { xs: '0px', sm: '100px', md: '140px', lg: '180px' }, pb: '50px' }}>
                    <SubCard>
                        <Stack spacing={'10px'} alignItems={'center'} width="100%"></Stack>
                    </SubCard>
                </Box>
            </MainCard>
        </>
    );
};

export default Support;
