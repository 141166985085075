// assets
import { IconWorld, IconPhone, IconBulb, IconDeviceTv, IconSchool, IconUserPlus, IconQuestionMark } from '@tabler/icons';

// constant
const icons = {
    IconWorld,
    IconPhone,
    IconBulb,
    IconDeviceTv,
    IconSchool,
    IconUserPlus,
    IconQuestionMark
};

// ==============================|| UTILITIES MENU ITEMS ||============================== //

const utilities = {
    id: 'utilities',
    title: 'Services',
    type: 'group',
    children: [
        {
            id: 'service-data',
            title: 'Data',
            type: 'item',
            url: '/services/data',
            icon: icons.IconWorld,
            breadcrumbs: false
        },
        {
            id: 'service-airtime',
            title: 'Airtime',
            type: 'item',
            url: '/services/airtime',
            icon: icons.IconPhone,
            breadcrumbs: false
        },
        {
            id: 'service-electricity',
            title: 'Electricity Bill',
            type: 'item',
            url: '/services/electricity',
            icon: icons.IconBulb,
            breadcrumbs: false
        },
        {
            id: 'service-tv',
            title: 'Cable Subscription',
            type: 'item',
            url: '/services/cable',
            icon: icons.IconDeviceTv,
            breadcrumbs: false
        },
        {
            id: 'service-school',
            title: 'Result Checker',
            type: 'item',
            url: '/services/result-checker',
            icon: icons.IconSchool,
            breadcrumbs: false
        },
        {
            id: 'service-referral',
            title: 'My Referral',
            type: 'item',
            url: '/',
            icon: icons.IconUserPlus,
            breadcrumbs: false
        },
        {
            id: 'frequently ask question',
            title: 'FAQ',
            type: 'item',
            url: '/#faq',
            icon: icons.IconQuestionMark,
            breadcrumbs: false
        }

        // {
        //     id: 'icons',
        //     title: 'More Stuff',
        //     type: 'collapse',
        //     icon: icons.IconWindmill,
        //     children: [
        //         {
        //             id: 'tabler-icons',
        //             title: 'More',
        //             type: 'item',
        //             url: '/',
        //             breadcrumbs: false
        //         },
        //         {
        //             id: 'material-icons',
        //             title: 'More Service',
        //             type: 'item',
        //             url: '/',
        //             breadcrumbs: false
        //         }
        //     ]
        // }
    ]
};

export default utilities;
